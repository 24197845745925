// @flow
import * as React from 'react'
import cn from 'classnames'
import { Store } from 'Store.js'
import { withTranslation, Trans } from 'react-i18next'
import { Currency, Percent } from 'i18n/NumberFormatter'
import Timer from 'components/Timer'

import SidebarFooter from 'components/SidebarFooter'

import withTheme from 'hoc/withTheme'

import supportedThemes from './themes/__supportedThemes.js'
import styles from './styles.module.scss'

import transDomain from './translations/index.translations'

function Sidebar (props) {
    const { t, theme, className } = props
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency(state.currency, state.locale)
    const voucher = state.campaign.incentives.voucher
    const incentives = state.campaign.incentives.manufacturer
    const hiddenCashBack = state.campaign.incentives.manufacturer?.cashBack === 0
    const hiddenInterestRate = state.campaign.incentives.manufacturer?.interestRate === 0

    return (
        <div className={cn(styles.container, className)} style={{
            backgroundColor: theme.bgColor,
            backgroundImage: `url(${theme.bgImage})`
        }}>
            <div className={styles.body}>
                <div className={styles.sidebarVoucherValue} style={{
                    backgroundColor: theme.primaryColor
                }}>
                    <span className={styles.title}
                        style={{
                            color: theme.titleColor
                        }}>
                        {t('title', {
                            voucher: state.campaign.incentives.voucher.name,
                            type: state.campaign.incentives.voucher.type
                        })}
                    </span>
                    <span className={styles.value}
                        style={{ color: theme.valueColor }}>
                        {currencyFormatter.format(voucher.value)}
                    </span>
                </div>

                {incentives && (
                    <div className={cn(styles.cashbackAndRates, {
                        [styles.hiddenCashBack]: hiddenCashBack,
                        [styles.hiddenInterestRate]: hiddenInterestRate,
                        [styles.areCombinable]: incentives.areCombinable
                    })}>

                        <div className={styles.cashBack} style={{
                            backgroundColor: theme.cashBackBg
                        }}>
                            <span className={styles.cashBackTitle} style={{ color: theme.cashBackTitleColor }}>
                                <Trans i18nKey="cash_back">
                                    Combine with  <br /><strong>Cash Incentives</strong> up to
                                </Trans>
                            </span>
                            <span className={cn(styles.cashBackValue, { [styles.smallValues]: props.small })} style={{ color: theme.valueColor }}>
                                {currencyFormatter.format(incentives.cashBack)}
                            </span>
                        </div>

                        <div className={styles.rates} style={{
                            backgroundColor: theme.ratesBg
                        }}>
                            <span className={styles.ratesTitle} style={{ color: theme.ratesTitleColor }}>
                                <Trans i18nKey="rates">
                                    Don't forget about<br />our <strong>Rates  as low as</strong>
                                </Trans>
                            </span>
                            <span className={cn(styles.ratesValue, { [styles.smallValues]: props.small })} style={{ color: theme.valueColor }}>
                                {Percent(state.locale).format((Number.parseFloat(incentives.interestRate).toPrecision(3) / 100) || 0)}
                            </span>
                        </div>

                    </div>
                )}
            </div>

            <div className={styles.timer}>
                <h3>{t('message')}</h3>
                <Timer small date={state.campaign.expiryDate} />
            </div>

            <div className={styles.footerContainer}>
                <SidebarFooter />
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Sidebar))
